<template>
    <div class="container">
        <div class="tj_title">安全中心-财富中心</div>
        <div class="center">
            <ul class="user_info">
                <li>
                    呱呱账号：<b>{{ userInfo.nickName }}({{ userInfo.id }}）</b>
                </li>
                <li>
                    元宝余额：<b>{{ userInfo.coins }}元宝</b>
                </li>
                <li>
                    鲜花余额：<b>{{ userInfo.flower }}朵</b>
                </li>
                <li>
                    购买鲜花<span style='color: #999999;'>（10元宝=1朵鲜花）</span>
                </li>
            </ul>

            <div class="buy_flower">
                <div
                    class="buy_item"
                    :class="{ active: activeIndex == 0 }"
                    @click="buyHandler(0, 1)"
                >
                    <b>1朵</b>
                    <span>10元宝</span>
                </div>
                <div
                    class="buy_item"
                    :class="{ active: activeIndex == 1 }"
                    @click="buyHandler(1, 5)"
                >
                    <b>5朵</b>
                    <span>50元宝</span>
                </div>
                <div
                    class="buy_item"
                    :class="{ active: activeIndex == 2 }"
                    @click="buyHandler(2, 10)"
                >
                    <b>10朵</b>
                    <span>100元宝</span>
                </div>
                <div
                    class="buy_item"
                    :class="{ active: activeIndex == 3 }"
                    @click="buyHandler(3, 50)"
                >
                    <b>50朵</b>
                    <span>500元宝</span>
                </div>
                <div
                    class="buy_item"
                    :class="{ active: activeIndex == 4 }"
                    @click="buyHandler(4, 100)"
                >
                    <b>100朵</b>
                    <span>1000元宝</span>
                </div>
                <div
                    class="buy_item custom"
                    :class="{ active: activeIndex == 5 }"
                    @click="buyHandler(5)"
                >
                    <p v-show="customShow == 1"><b>自定义输入</b></p>
                    <div v-show="customShow == 2">
                        <div class="flower_num input_no_spin">
                            <input
                                type="number"
                                ref="customInp"
                                v-model="customNum"
                                @blur="handleBlur"
                                @input="handleInput"
                            />朵
                        </div>
                        <span>{{ customNum * 10 || 0 }}元宝</span>
                    </div>
                    <div v-show="customShow == 3">
                        <div class="flower_num">{{ customNum }}朵</div>
                        <span>{{ customNum * 10 || 0 }}元宝</span>
                    </div>
                </div>
            </div>

            <div class="go_buy" @click="goBuy">立即购买</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeIndex: 0,
            num: 1,
            customNum: "",
            customShow: 1,
            whrite: true,
            userInfo: {
                coins: "",
                flower: "",
                nickName: "",
                id: "",
            },
        };
    },
    methods: {
        handleInput() {
            let { customNum } = this;
            if (customNum.length > 6)
                this.customNum = this.customNum.slice(0, 6);
        },
        handleBlur() {
            let { customNum } = this;
            if (customNum === "") {
                this.customShow = 1;
                this.num = "";
            } else {
                this.customShow = 3;
                this.num = customNum;
            }
        },
        buyHandler(index, num) {
            this.activeIndex = index;
            if (num) {
                this.num = num;
            } else {
                this.customShow = 2;
                this.$nextTick(() => {
                    this.$refs.customInp.focus();
                });
            }
        },
        goBuy() {
            let { num } = this;
            if (num === "") {
                alert("请输入正确数量");
                return;
            }
            this.postRequest("/billing/buy/flower.wp", { num }).then((res) => {
                console.log(res);
                if (res.data.state == 0) {
                    alert("支付成功");
                    this.getCoins();
                    this.getFlower();
                } else {
                    alert(res.data.msg);
                }
            });
        },
        getCoins() {
            this.postRequest("/billing/user/userVirtualCurrencies.wp").then(
                (res) => {
                    if (res.data.state == 0) {
                        this.userInfo.coins = res.data.content.coins;
                    }
                }
            );
        },
        getFlower() {
            this.postRequest("/flower/user/get.wp").then((res) => {
                if (res.data.state == 0) {
                    this.userInfo.flower = res.data.obj.haveFlowerNum;
                }
            });
        },
    },
    mounted() {
        let { nickName, id } = window._user;
        this.userInfo.nickName = nickName;
        this.userInfo.id = id;
        this.getCoins();
        this.getFlower();
    },
};
</script>
<style scoped lang="scss" type="text/css">
.container {
    width: 100%;
    min-height: 500px;
    background: #fff;
    border-radius: 5px;
    .tj_title {
        text-align: left;
        color: #333333;
        font-size: 16px;
        padding: 22px 0 22px 22px;
        border-bottom: 1px solid #d2d2d2;
    }
    .center {
        display: flex;
        flex-direction: column;

        .user_info {
            margin-bottom: 0;
            li {
                font-size: 16px;
                margin-bottom: 26px;
                text-align: left;

            }
        }

        .buy_flower {
            padding: 0 30px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            margin-top: -11px;
            .buy_item {
                border-radius: 5px;
                width: 130px;
                height: 66px;
                border: 2px solid #eeeeee;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0;
                padding: 0 15px;
                box-sizing: border-box;
                cursor: pointer;
                b {
                    margin-bottom: 5px;
                    font-size: 18px;
                }
                span {
                    color: #999999;
                    font-size: 16px;
                }
                &.active {
                    border: 2px solid #f15353;
                }
                &.custom {
                    .flower_num {
                        display: flex;
                        font-weight: bold;
                        font-size: 18px;
                        justify-content: center;
                        input {
                            width: 60px;
                            height: 25px;
                            outline: none;
                            border: none;
                            border-bottom: 0.5px solid #999999;
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .go_buy {
            width: 200px;
            height: 46px;
            background: #4d9dfc;
            border-radius: 5px;
            font-size: 16px;
            color: #ffffff;
            line-height: 46px;
            position: relative;
            top: 51px;
            margin: auto;
            cursor: pointer;
        }
    }

    .input_no_spin {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0;
        }
    }
}
</style>
